import { Row, Col, Tooltip, Button } from 'antd'
import styled from 'styled-components'

import { overridePrimary, overrideButtonText, overrideSecondary } from '../constants/partner'

const BuildButton = styled(Button)`
  background-color: ${overrideSecondary} !important;
  color: ${overrideButtonText} !important;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  :hover {
    border: 1px solid ${overrideSecondary} !important;
    color: ${overrideButtonText} !important;
    font-weight: 600;
  }

  &:disabled {
    background-color: #eaecef !important;
    color: gray !important;
  }
`
const CancelButton = styled(Button)`
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  &:disabled {
    background-color: #eaecef !important;
    color: gray !important;
  }
`

import { SectionHeaderTitle } from './section-header-title'
import SlatesSelect from './slates-select'
import NumberOfLineupsBar from './NumberOfLineupsBar'

const StyledBuildButtonCol = styled(Col)`
  @media (max-width:767px) {
    margin-top: 15px
    button {
      float: right;
    }
  }
`

const OptimizerTopSection = ({
  sport, 
  site, 
  slate, 
  counter, 
  season, 
  changeDateInfo, 
  updateSite, 
  updateSlate,
  loading,
  cancelOpt,
  getLineups,
  valid,
  afterStartActivated,
  errors=[],
  handbuildMode
}) => {

  return (
    <Row>
      <Col lg={12} md={12} sm={24} xs={24}>
        <SectionHeaderTitle 
          sport={sport} 
          site={site} 
          counter={counter} 
          season={season} 
          changeDateInfo={changeDateInfo} 
          updateSite={updateSite} 
          updateSlate={updateSlate}
          slate={slate}
        />
      </Col>
      <Col lg={9} md={8} sm={12} xs={12}>
        <NumberOfLineupsBar 
          site={site} 
          slate={slate} 
          counter={counter} 
          season={season} 
          sport={sport}
        />
      </Col>
      <StyledBuildButtonCol lg={3} md={4} sm={12} xs={12}>
        { loading ? (
            <CancelButton onClick={cancelOpt} disabled={!loading}>
              Cancel
            </CancelButton>
          ) : (
            valid ? (
              <BuildButton onClick={getLineups} disabled={false}>
                {
                    afterStartActivated ? (
                      <>Build After Start</>
                    ) : (
                      <>Build</>
                    )
                  }
              </BuildButton>
            ) : (
              <Tooltip placement="bottom" title=
                {
                   (
                    errors.map((_error, i) => (
                      <div key={`error-${i}`}>
                        - {_error}
                      </div>
                    ))
                  )
                  
                }
              >
                <BuildButton disabled={true}>
                  {
                    afterStartActivated ? (
                      <>Build After Start</>
                    ) : (
                      <>Build</>
                    )
                  }
                </BuildButton>
              </Tooltip>
            )
          )
        }
      </StyledBuildButtonCol>
    </Row>
  )
}

export default OptimizerTopSection